import { useCallback, useEffect, useState } from "react";
import {
  ProductService,
  NotificationTypes,
  useNotification,
  PagingData,
  KeyValueParams,
  ProductVariant,
  LogService,
  ProductVariantVersions,
} from "lib-core";
import { atom, useAtom, useAtomValue } from "jotai";

type ProductVariantData = {
  error: unknown | undefined;
  variantsLoading: boolean;
  variantLoading: boolean;
  fetchProductVariantsList(
    pagingData: PagingData,
    metadata: KeyValueParams
  ): void;
  refetchSelectedProductVariant(): void;
  productVariantsList: ProductVariant[] | undefined;
  productVariantsTotal: number;
  productVariant: ProductVariant | undefined;
  setSelectedProductVariant: (p: ProductVariant) => void;
};

const selectedProductVariantAtom = atom<ProductVariant | undefined>(undefined);

const useProductVariant = (): ProductVariantData => {
  const [variantsLoading, setVariantsLoading] = useState<boolean>(false);
  const [variantLoading, setVariantLoading] = useState<boolean>(false);
  const [productVariantsList, setProductVariantsList] =
    useState<ProductVariant[]>();
  const [productVariant, setSelectedProductVariant] = useAtom(
    selectedProductVariantAtom
  );
  const [error, setError] = useState<unknown>();
  const notification = useNotification();
  const [count, setCount] = useState(0);

  const fetchProductVariantsList = async (
    pagingData: PagingData,
    filters: KeyValueParams
  ) => {
    setVariantsLoading(true);
    setError(undefined);

    try {
      const metadata = (filters || [])
        .filter((p) => p.value !== "")
        .reduce((acc, filter) => {
          const { key, value } = filter;

          if (key) {
            return [...acc, { key, value }];
          }
          return acc;
        }, [] as KeyValueParams);

      const fetchedProducts = await ProductService.getProductVariants(
        pagingData,
        metadata
      );

      setProductVariantsList(fetchedProducts.entities);
      setCount(fetchedProducts.paging.total ?? 0);
    } catch (err) {
      setVariantsLoading(false);
      setError(err);
    }
    setVariantsLoading(false);
  };

  const refetchSelectedProductVariant = useCallback(async () => {
    setVariantLoading(true);
    setError(undefined);
    if (productVariant?.id)
      try {
        const extProductVariant = await ProductService.getProductVariant(
          productVariant?.id
        );

        setSelectedProductVariant(extProductVariant);
      } catch (err) {
        setVariantLoading(false);
        setError(err);
        const { response } = err as unknown as {
          response: { data: { detail: string } };
        };

        const { detail } = response?.data ?? {
          detail: "Error while fetching product variant information",
        };
        notification.createNotification({
          severity: "error",
          type: NotificationTypes.Snackbar,
          message: detail,
        });
      }
    setVariantLoading(false);
  }, [notification, productVariant?.id, setSelectedProductVariant]);

  return {
    variantsLoading,
    variantLoading,
    error,
    fetchProductVariantsList,
    refetchSelectedProductVariant,
    productVariantsList,
    productVariant,
    setSelectedProductVariant,
    productVariantsTotal: count,
  };
};

const VARIANT_REFRESH_RATE = 10000;

const selectedProductVariantVersionsAtom = atom<
  ProductVariantVersions | undefined
>(undefined);

export const useSelectedProductVariantVersions = () => {
  const { productVariant: selectedProduct } = useProductVariant();
  const versions = useAtomValue(selectedProductVariantVersionsAtom);

  const hasSelectedProductChanged = (() => {
    // If no selected product or fetched versions, then there is nothing to compare for changes
    if (!selectedProduct || !versions) {
      return false;
    }

    return selectedProduct.ver !== versions.productVariant;
  })();
  return {
    versions,
    hasSelectedProductChanged,
  };
};

export const useFetchProductVariantVersion = () => {
  const { productVariant: selectedProduct } = useProductVariant();
  const [selectedProductVariantVersions, setSelectedProductVariantVersions] =
    useAtom(selectedProductVariantVersionsAtom);

  useEffect(() => {
    if (selectedProduct) {
      setSelectedProductVariantVersions(undefined);
      const fetchDataAndCompare = async () => {
        try {
          const versions = await ProductService.getProductVariantVersions(
            selectedProduct.id
          );
          setSelectedProductVariantVersions(versions);
        } catch (error) {
          LogService.error(
            "Error fetching data for product variant comparison:",
            error
          );
        }
      };

      // Start the interval if selectedProduct.id exists
      const intervalId = setInterval(fetchDataAndCompare, VARIANT_REFRESH_RATE); // Call every 10 seconds

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }
    return () => {
      // no-op
    };
  }, [selectedProduct, setSelectedProductVariantVersions]);

  return selectedProductVariantVersions;
};

export default useProductVariant;
