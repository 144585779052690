import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  ImportModel,
  PermissionKeys,
  PermissionRequired,
  Model,
  ModelStatuses,
  ModelTrainingStatus,
  ModelApprovalData,
  LogService,
  formatToDateOnly,
  ProductVariantSnapshot,
  usePermissionChecking,
  ModelLogsDownloadRequest,
  useNotification,
  NotificationTypes,
  ProductVariant,
} from "lib-core";
import { Button, Typography } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ModalApprove from "./ModalApprove";
import LibraryTable, {
  LibraryTableHeaders,
} from "../components/LibraryTable/LibraryTable";
import { TableRowSet } from "../components/LibraryTable/LibraryTableBody";

import useProductVariantTraining from "./productVariantTraining-hook";
import { trainingHeaders, ModelWithButtons } from "./helpers";
import LibrarySubheader from "./LibrarySubheader";
import { useBlendedSnapshot } from "./blendedSnapshot-hook";

interface TrainingTabProps {
  productVariant: ProductVariant;
}

const ActionButtons = styled.nav`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
`;

const CustomIconButton = styled(Button)`
  min-width: 0px;
  padding: 5px;
`;

const BlendedSnapshotText = styled(Typography)`
  color: ${({ theme }) => theme.palette.brandGray.dark};
`;

// Mapping for more user friendly format of status
const statusMapping: Record<ModelStatuses, string> = {
  [ModelStatuses.IN_TRAINING]: "In training",
  [ModelStatuses.WAITING_APPROVAL]: "Waiting approval",
  [ModelStatuses.APPROVED_PRODUCTION]: "Approved, production",
  [ModelStatuses.NOT_APPROVED]: "Not approved",
  [ModelStatuses.APPROVED_CANDIDATE]: "Approved, candidate",
  [ModelStatuses.DECOMMISSIONED]: "Decommissioned",
  [ModelStatuses.REJECTED]: "Rejected",
  [ModelStatuses.ERROR]: "Failed",
};

const { readModelling, writeModelling, createModelling, readBlendedSnapshot } =
  PermissionKeys;

const TrainingTab: (props: TrainingTabProps) => JSX.Element = ({
  productVariant,
}) => {
  const [isModifyingTraining, setIsModifyingTraining] = useState(false);
  const { usableSnapshots: blendedSnapshots = [] } = useBlendedSnapshot();
  const {
    cancelModelTrainingRun,
    fetchTrainingModels,
    models,
    loadingModels,
    loadingPlatforms,
    changeLoading,
    approveModel,
    getProductVariantSnapshots,
    snapshots,
    downloadModelTrainingLogs,
    rejectModel,
    fetchPlatforms,
    platforms,
  } = useProductVariantTraining();

  const [downloadingRunId, setDownloadingRunId] = useState<
    string | undefined
  >();
  const requirePermission = usePermissionChecking();
  const hasModelPlatformPermissions = requirePermission([createModelling]);

  // Fetch product variant information by id and fecth models for list
  const fetchTrainingTabData = async () => {
    await Promise.allSettled([
      fetchTrainingModels(productVariant.id),
      ...(hasModelPlatformPermissions
        ? [getProductVariantSnapshots(productVariant.id), fetchPlatforms()]
        : []),
    ]);
  };

  const [modal, setModal] = useState(false);
  const [modelName, setName] = useState<string>();
  const [modalState, setModalState] = useState<"approve" | "reject">("approve");
  const [currentApprovalData, setCurrentApprovalData] =
    useState<ModelApprovalData>();

  const { createNotification } = useNotification();

  const onModalClose = () => {
    setModal(false);
    setName("");
  };

  const onModalOpen = (
    modelVersionId: number,
    modelExternalName: string,
    modelName: string,
    state: "approve" | "reject"
  ) => {
    setCurrentApprovalData({ modelVersionId, modelName });
    setModal(true);
    setName(`${modelExternalName} (version ${modelVersionId})`);
    setModalState(state);
  };

  // Usable snapshots to start trainings
  const releasedSnapshots =
    productVariant?.metadata.snapshotApprovalStatus.released ?? [];

  const filteredSnapshots = snapshots.filter((obj: ProductVariantSnapshot) => {
    return releasedSnapshots.includes(obj.id);
  });

  const findPreviousCandidate = () => {
    const approved = models.find(
      (model) => model.modelStatus === ModelStatuses.APPROVED_CANDIDATE
    );
    if (approved) {
      return `${approved?.modelExternalName} (version ${approved?.modelVersionId})`;
    }
    return null;
  };

  useEffect(() => {
    fetchTrainingTabData();
  }, [productVariant]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancelingTraining = (viewId: number) => {
    cancelModelTrainingRun(viewId)
      .then(() => {
        fetchTrainingTabData();
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  const handleApprovingModel = (modelApprovalData: ModelApprovalData) => {
    setModal(false);
    setIsModifyingTraining(true);
    approveModel(modelApprovalData)
      .then(() => {
        fetchTrainingTabData();

        setCurrentApprovalData({ modelVersionId: 0, modelName: "" });
      })
      .catch((err) => {
        LogService.error(err);
      })
      .finally(() => setIsModifyingTraining(false));
  };

  const handleRejectingModel = (modelApprovalData: ModelApprovalData) => {
    setModal(false);
    setIsModifyingTraining(true);
    rejectModel(modelApprovalData)
      .then(() => {
        fetchTrainingTabData();

        setCurrentApprovalData({ modelVersionId: 0, modelName: "" });
      })
      .catch((err) => {
        LogService.error(err);
      })
      .finally(() => setIsModifyingTraining(false));
  };

  const onClickDownloadModelTrainingLogs = (
    request: ModelLogsDownloadRequest
  ) => {
    setDownloadingRunId(request.runId);
    downloadModelTrainingLogs(request)
      .catch(() => {
        createNotification({
          type: NotificationTypes.Snackbar,
          severity: "error",
          message: `Failed to download the log file, please try again`,
        });
      })
      .finally(() => {
        setDownloadingRunId(undefined);
      });
  };

  const downloadLogButton = ({ modelName, runId, logsAvailable }: Model) => {
    return (
      <PermissionRequired
        key="download-logs"
        hidden
        permissionKeys={[readBlendedSnapshot]}
      >
        <CustomIconButton
          disabled={!!downloadingRunId || !logsAvailable}
          variant="outlined"
          onClick={() => {
            onClickDownloadModelTrainingLogs({ modelName, runId });
          }}
        >
          <DescriptionOutlinedIcon />
        </CustomIconButton>
      </PermissionRequired>
    );
  };

  const actionButtons = (model: Model) => {
    const {
      modelStatus,
      viewId,
      modelVersionId,
      modelExternalName,
      modelName,
    } = model;
    let buttons: JSX.Element[] = [downloadLogButton(model)];

    if (modelStatus === ModelStatuses.WAITING_APPROVAL) {
      buttons = buttons.concat(
        <PermissionRequired
          key="approve"
          hidden
          permissionKeys={[readModelling, writeModelling]}
        >
          <Button
            onClick={() =>
              onModalOpen(
                modelVersionId,
                modelExternalName,
                modelName,
                "approve"
              )
            }
            size="small"
          >
            Approve
          </Button>
        </PermissionRequired>,
        <PermissionRequired
          key="reject"
          hidden
          permissionKeys={[readModelling, writeModelling]}
        >
          <Button
            onClick={() =>
              onModalOpen(
                modelVersionId,
                modelExternalName,
                modelName,
                "reject"
              )
            }
            variant="outlined"
            size="small"
          >
            Reject
          </Button>
        </PermissionRequired>
      );
    }
    if (modelStatus === ModelStatuses.IN_TRAINING) {
      buttons = buttons.concat(
        <PermissionRequired
          hidden
          permissionKeys={[readModelling, writeModelling]}
        >
          <Button
            onClick={() => fetchTrainingTabData()}
            disabled={changeLoading}
            variant="contained"
            size="small"
          >
            Refresh
          </Button>
        </PermissionRequired>,
        <PermissionRequired
          key="reject"
          hidden
          permissionKeys={[readModelling, writeModelling]}
        >
          <Button
            onClick={() => handleCancelingTraining(viewId)}
            disabled={changeLoading}
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
        </PermissionRequired>
      );
    }

    if (buttons.length === 0) return null;
    return <ActionButtons>{buttons}</ActionButtons>;
  };

  // Set rowsets
  const statuses: [ModelStatuses[], string][] = [
    [[ModelStatuses.IN_TRAINING, ModelStatuses.WAITING_APPROVAL], "New models"],
    [
      [ModelStatuses.APPROVED_PRODUCTION, ModelStatuses.APPROVED_CANDIDATE],
      "Approved",
    ],
    [[ModelStatuses.DECOMMISSIONED], "Decommissioned"],
    [[ModelStatuses.ERROR], "Failed trainings"],
  ];

  // Model status is not quite straigthforward and needs to be resolved.
  const resolveModelStatus = (model: Model) => {
    // If model is in training
    if (model.modelTrainingStatus === ModelTrainingStatus.TRAINING) {
      return ModelStatuses.IN_TRAINING;
    }

    // If model is failed
    if (model.modelTrainingStatus === ModelTrainingStatus.ERROR) {
      return ModelStatuses.ERROR;
    }

    // If model is in approval status
    if (
      model.modelTrainingStatus === ModelTrainingStatus.DONE &&
      ModelStatuses.NOT_APPROVED === model.modelStatus &&
      model.pendingStatus === null
    ) {
      return ModelStatuses.WAITING_APPROVAL;
    }

    // Approved and decommissioned statuses are clear so these can be returned as is
    return model.modelStatus;
  };

  // Map models and resolve status
  const mappedModels = (models ?? []).map((model) => ({
    ...model,
    modelStatus: resolveModelStatus(model),
  }));

  // Map all model metrics into one object
  const headerObject = Object.assign(
    {},
    ...(models ?? [])
      .filter(
        (model) =>
          model.modelStatus !== ModelStatuses.REJECTED &&
          model.modelStatus !== ModelStatuses.DECOMMISSIONED
      )
      .map((model) => ({
        ...model.modelMetrics,
      }))
  );

  // Map the object into key-label pairs (headers)
  const extraHeaders = Object.keys(headerObject).map((key) => {
    return {
      key,
      label: key,
    };
  });

  // Join the original headers and the extra headers
  const joinedHeaders = trainingHeaders.concat(extraHeaders);

  // Map statuses to rows and set subheader label for each of them
  const rowSets: TableRowSet<ModelWithButtons>[] = statuses.map(
    ([statuses, title]) => {
      // Filtering rows with status and map with action buttons
      const rows = mappedModels
        .filter((model) => {
          const { modelStatus } = model;
          return statuses.includes(modelStatus);
        })
        .map((model) => {
          let blendedSnapshotText = "";
          if (model.blendedSnapshot) {
            blendedSnapshotText = `
            Blended: ${formatToDateOnly(model.blendedSnapshot.createdOn)}, V${
              model.blendedSnapshot.version
            }.0
            `;
          }
          return {
            ...model,
            ...model.modelMetrics,
            modelStatus: statusMapping[model.modelStatus], // Map status to more user friendly
            snapshots: (
              <>
                <Typography variant="body2">{`Product Variant: ${formatToDateOnly(
                  model.modelSnapshot.createdOn
                )}, V${model.modelSnapshot.version}.0`}</Typography>
                <BlendedSnapshotText variant="body2" color="brandGray">
                  {blendedSnapshotText}
                </BlendedSnapshotText>
              </>
            ), // Show snapshot date instead of object
            actionButtons: actionButtons(model),
          };
        });

      return {
        label: [
          {
            label: `${title} (${rows.length})`,
            colspan: joinedHeaders.length,
          },
        ] as LibraryTableHeaders<Record<string, number>>,
        rows,
      };
    }
  );

  const rowCount = useMemo(() => {
    let count = 0;
    models.forEach((model) => {
      if (model.modelStatus !== ModelStatuses.REJECTED) {
        count += 1;
      }
    });
    return count;
  }, [models]);

  const isAnythingLoading =
    loadingModels || loadingPlatforms || isModifyingTraining;

  return (
    <>
      <LibrarySubheader
        loading={isAnythingLoading}
        loadingTitle="Loading model packages..."
        title={
          rowCount === 1
            ? "1 model package added"
            : `${rowCount} model packages added`
        }
        buttons={
          <PermissionRequired hidden permissionKeys={[createModelling]}>
            <ImportModel
              disabled={isAnythingLoading}
              productVariantSnapshots={filteredSnapshots}
              blendedSnapshots={blendedSnapshots}
              onSuccess={fetchTrainingTabData}
              platforms={platforms}
            />
          </PermissionRequired>
        }
      />

      <ModalApprove
        state={modalState}
        open={modal}
        onClose={onModalClose}
        onSubmit={() =>
          currentApprovalData && modalState === "approve"
            ? handleApprovingModel(currentApprovalData)
            : currentApprovalData && handleRejectingModel(currentApprovalData)
        }
        newCandidateName={modelName || "New candidate"}
        oldCandidateName={findPreviousCandidate()}
      />

      <LibraryTable
        headers={joinedHeaders}
        rowSets={rowSets}
        loading={isAnythingLoading}
      />
    </>
  );
};

export default TrainingTab;
